import "./subscription.css"
import Date from "./Date"
import {useState} from "react";
function Subscription(props){
    const [title,setTitle]= useState(props.title)
    const [amount,setAmount]= useState(props.amount)
    const changeTitle = () =>{
        setTitle("Title has been changed ")
        setAmount("399.90")
    }
    return(
        <div className="subscription">
            <div className="subscription_date">
                <Date date={props.date}/>
            </div>
            <div className="subscription_title"><span>{title}</span></div>
            <div className="subscription_price"><span> <b>{amount}₹</b></span></div>
            <div className="subscription_button"> <button onClick={changeTitle}>Click Me</button></div>
        </div>
    )
}
export default Subscription
