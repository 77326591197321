import "./date.css";
function Date(props){
    let month=props.date.toLocaleString('default', {month:"long"});
    let day=props.date.toLocaleString('default', {day:"2-digit"});
    let year=props.date.getFullYear();
    return(
        <div className="date">
                 <span className="month">{month}</span>
                <span className="day">{day}</span>
                <span className="year">{year}</span>
                
        </div>
        
    )
}
export default Date;