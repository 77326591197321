import { useState } from "react";
import FormSubscription from "./FormSubscription"
import "./newsubscription.css";
import Plusicon from "../../images/plus.png"
const NewSubscription=(props)=>{

const [showForm, setShowForm]= useState(false)
    //on save data handler
    const onSaveHandler = (data) => {
         const subscriptionData = {
           ...data,
           id: Math.floor(Math.random() * 90).toString()
         };
        props.addSubscription(subscriptionData)

        console.log("New Subscription File", subscriptionData);   
        hideForHandler();
    }
    // show form handler
   const showFormHandler = ()=>{
    setShowForm(true)
    }
    
   const hideForHandler=()=>{
    setShowForm(false);
    }
    return (
      <div>
        {showForm && <FormSubscription onSave={onSaveHandler} cancel={hideForHandler}/>}
        {/* {!showForm && <button type="button" onClick={showFormHandler} className="addNewSubscriptionBtn">  </button>} */}
        {!showForm && <img src={Plusicon} alt="" onClick={showFormHandler} className="addNewSubscriptionBtn"/>}
     

      </div>
    );
}
export default NewSubscription