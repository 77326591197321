import { useEffect, useState } from "react";
import "./formsubscription.css";

const FormSubscription = (props) => {
  const intialStates = { date: "", title: "", amount: "" };
  // from data useState 
  const [formData, setFormData] = useState(intialStates);
  
  // validation useState declearation
    const [isValid, setIsValid] = useState(true)

// on Change Handler
  const onChangeHandler = (event) => {

    setFormData({ ...formData, [event.target.name]: event.target.value });
    //  setIsValid(true);
  }; 

  //useEffect code for validation
    useEffect(() => {
    const timeOut=  setTimeout(()=>{
      
        if (formData.title.trim().length>0) {
          setIsValid(true);
          console.log("Set validation vala chal raha hai");
        }
      }, 500)
    return ()=>{
      clearTimeout(timeOut)
      console.log("TimeOut")
    }  
    }, [formData.title]);

//submit handler here
  const submitHandler = (event)=>{
    event.preventDefault();
    // checking Validation here
    if (formData.title.trim().length === 0) {
      setIsValid(false);
      return;
    }

// passing data child to parent 
  const newSubscriptionData = formData;
    props.onSave(newSubscriptionData);
    
  }
  
  return (
    <div className="form-controler">
      <div className="new-sub">
        <h1>New Subscription </h1>
        <hr />
        <form onSubmit={submitHandler}>
          <div className="new-sub-date">
            <label>Date</label>
            <input type="date" name="date" onChange={onChangeHandler} />
          </div>

          <div className={`new-sub-title ${!isValid ? "is-valid" : " "}`}>
            <label>Title</label>
            <input type="text" value={formData.title} name="title" onChange={onChangeHandler} />
            {!isValid && <p> This field is Required</p>}
          </div>

          <div className="new-sub-price">
            <label>Price</label>
            <input type="text" name="amount" onChange={onChangeHandler} />
          </div>
          <div className="new-sub-button">
            <button type="button" className="cancel" onClick={props.cancel}>
              Cancel
            </button>
            <button type="submit" className="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default FormSubscription;
