import './filter.css'
const Filter = (props)=>{
  const filterData=props.selectedFilterData;
    // const totalYear = props.totalYear

 const filterOnChangeHandler = (event)=>{
    console.log("On Change ",event.target.value)
    props.filterChange(event.target.value)

}

    return(
       <div className='filter'>
        <div>Data by Filter</div>
        <select value={filterData} onChange={filterOnChangeHandler}>

            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2013">2013</option>
        </select>
       </div>
    )
}
export default Filter