import './App.css';
import NewSubscription from './Subscription/NewSub/NewSubscription';
import Filter from './Subscription/Filter';
import { useEffect, useState } from 'react';
import SubscriptionList from './Subscription/SubscriptionList';

let INITIAL_subscription = [
  {
    id: "1",
    date: new Date("July 1, 2022 19:15:20"),
    title: "Monthly Subscription",
    amount: "129.50",
  },
  {
    id: "2",
    date: new Date("july 6, 2021 13:15:20"),
    title: "Quaterly Subscription",
    amount: "319.00",
  },
  {
    id: "3",
    date: new Date("June 9, 2024 02:15:20"),
    title: "Yearly Subscription",
    amount: "649.00",
  },
];
// let jsonData =Data.subscription
function App() {

  const [subscription, setSubscription] = useState(INITIAL_subscription);

  const addSubscriptionHandler =(data)=>{
    const subData = {
      id: data.id,
      date: new Date(data.date),
      title: data.title,
      amount: data.amount
    };  
    setSubscription([subData,...subscription])
    
  }

//fiter
const [filterYear, setFilterYear] = useState("2024");

useEffect(() => {
  if (localStorage.getItem("abc")) {
    setFilterYear(localStorage.getItem("abc"));
    console.log("Local Storage vala chal raha hai");
  }
}, [filterYear]);

const onFilterChange = (data)=>{
  setFilterYear(data);
  localStorage.setItem("abc",data)
// console.log("Getting Data ", data)
}

//filter array
const filterSubscription = subscription.filter((item)=>{
  return item.date.getFullYear().toString() === filterYear
})
// total year of subscription
const totalYear = subscription.map(subscription=> subscription.date.getFullYear().toString())

  
  //main app return 
  return (
    <div className="App">
      <div className="container">
        <NewSubscription addSubscription={addSubscriptionHandler} />
        <Filter
          totalYear={totalYear}
          selectedFilterData={filterYear}
          filterChange={onFilterChange}
        />
       
        <SubscriptionList subscription={filterSubscription} year={filterYear} />
      </div>
    </div>
  );
}

export default App;
