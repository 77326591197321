
import nodata from "../images/nodata2.gif";
import "./nodata.css";
const NoDataFound = (props) => {

  // const headingYear = props.year;
  // console.log(headingYear)

  return (
    <div className="nodatafound">
      <h1> No Data Found in {props.year}</h1>
      <img src={nodata} alt="" />
    </div>
  );
};
export default NoDataFound;
