import NoDataFound from "../template/NoDataFound"
import Subscription from "./Subscription"
const SubscriptionList = (props)=>{

if (props.subscription.length===0) { return <NoDataFound year={props.year}/> }

   return (
     <div>
       {props.subscription && <h1>Subscription List</h1>}
       {props.subscription.map((subscription) => (
         <Subscription
           key={subscription.id}
           date={subscription.date}
           title={subscription.title}
           amount={subscription.amount}
         />
       ))}
     </div>
   );

}
export default SubscriptionList